import React, { useState, useEffect } from "react";
import "./FrankenDogBowlEvents.css";
import { Card, CardContent, Grid, IconButton } from "@mui/material";
import DeviceSnSuffixSearch from "../../../../utils/DeviceSnSuffixSearch";
import { useSearchParams } from "../../../../utils/useSearchParams";
import axiosInstance from "../../../../api/axios/axiosInstance";
import LoadingSpinner from "../../../LoadingSpinner/component";
import DogBowlEventTable from "../../../Table/DogBowlEventsTable/DogBowlEventsTable";


const FrankenDogBowlEventsHome = () => {
  const [newData, setNewData] = React.useState();
  const [error, setError] = useState('');
  const [searchParams, setSearchParams] = useSearchParams({
    page: 1,
    pageSize: 50,
    snSuffix: "",
    // eventType: "",
    // sort: "START_TIME_DESC"
  })
  const [isLoading, setisLoading] = React.useState(true);

  useEffect(() => {
    setisLoading(true);
    
    let url = "/get-franken-dog-bowl-pets-events-home"

    

    axiosInstance.post(url, {
      count: 50,
      event_id: searchParams.get("snSuffix"),
      page: searchParams.get("page"),
      source: "dbf1"
    },
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response, error) => {
        setisLoading(false);
        setNewData(response.data);
        setError(error);
      }).catch((error) => {
        setisLoading(false);
      })



  }, [searchParams])

  const handleSearch = (newSnSuffix) => {
    let newSearchParams = {
      ...Object.fromEntries(searchParams),
      page: 1,
      snSuffix: newSnSuffix.trim(),
    }
    if (newSnSuffix === "") {
      delete newSearchParams["snSuffix"]

    }
    setisLoading(true)
    setSearchParams(newSearchParams);
  };

  const pagination = {
    current: searchParams.get("page")
      ? Number(searchParams.get("page"))
      : 0,
    pageSize: searchParams.get("pageSize")
      ? Number(searchParams.get("pageSize"))
      : 0,
    onChange: (page, pageSize) => {
      setSearchParams({
        ...Object.fromEntries(searchParams),
        page,
        pageSize,
      })
    },
    total: parseInt(newData?.pageInfo.totalCount ?? 0),
  }

  const dataTransformedForTable = newData?.data.map((monitor) => {
    return {
      ...monitor,
      key: monitor.id,
      startTime: monitor.startTime ?? ""
    }
  }) ?? []


  
  return !isLoading ? (
    <div className="module-content">
      <h5>Events</h5>
      <Card>
        <CardContent>
          <Grid container alignItems="center">
            <Grid item xs>
              <DeviceSnSuffixSearch onSearch={handleSearch} defaultValue={searchParams.get("snSuffix") ?? ""} placeholder="Input Event ID" />
            </Grid>

          </Grid>
          <br></br>

          <Grid container>
            <DogBowlEventTable
              data={dataTransformedForTable}
              loading={false}
              pagination={pagination}
              deviceType={"frankenbowl"}
            />
          </Grid>
        </CardContent>
      </Card>

    </div>
  ) : (
    <LoadingSpinner />
  );
};

export default FrankenDogBowlEventsHome;
