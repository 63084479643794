import React from "react";
import { Box, CircularProgress, Grid } from "@mui/material";
import { useParams } from "react-router-dom";

import axiosInstance from "../../../../api/axios/axiosInstance";
import Page from "../../../../utils/Page";
import FrankenDogBowlEventKeyInfo from "./FrankenDogBowlEventKeyInfo";
// import DogBowlLoadCellChart from "../../../../utils/eventUtills/DogBowlEventLoadCellChart";
// import DogBowlLabelsAndClassifications from "../../../../utils/eventUtills/DogBowlLabelsAndClassifications";
import FrankenDogBowlLabelsAndClassifications from "../../../../utils/eventUtills/FrankenDogBowlLabelsAndClassifications";

export const FrankenDogBowlEventView = ({ eventId }) => {
  const [newData, setNewData] = React.useState();

  const [isLoading, setIsLoading] = React.useState(true);
  const [error, setError] = React.useState('');

  React.useEffect(() => {
    eventLoad();

  }, [eventId, setNewData]);

  const eventLoad = () => {
    let url = "/get-franken-dog-bowl-pets-events-home"

    axiosInstance.post(url, {
      count: 50,
      event_id: eventId,
      page: 1,
      source: "dbf1"
    },
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response, error) => {
        setIsLoading(false);
        setNewData(response.data);

      }).catch((error) => {
        setIsLoading(false);
        setError(error);
      })

  };



  return (
    <Page
      title="Event"
      breadcrumbs={[
        {
          title: "Household",
          link: `/households/${newData?.household_id}`,
        },
        {
          title: "Monitor",
          link: `/monitors/${newData?.machine_id}`,
        },
        { title: eventId },
      ]}
    >
      {!isLoading ? (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FrankenDogBowlEventKeyInfo eventId={eventId} serialNumber={newData?.data[0]?.device_serial_nos} />
          </Grid>
          {/* <Grid item xs={12}>
            <DogBowlLoadCellChart
              eventId={eventId}
              sessionStartTime={newData?.session_start_date}
              cloud_video_file_url={newData?.data[0]?.cloud_video_file_url}
            />
          </Grid> */}

          <Grid item xs={12}>

            <FrankenDogBowlLabelsAndClassifications
              eventId={eventId ?? {}}
              startTime={newData?.data[0]?.startTime ?? {}}
              cloudVideoFileUrl={newData?.data[0]?.cloud_video_file_url ?? {}}
              dogId={newData?.data[0]?.pet_id ?? {}}
              videoStartTime={newData?.data[0]?.video_start_time ?? {}}
              machineId={newData?.data[0]?.serial_no ?? {}}
              petName={newData?.data[0]?.pet_name ?? {}}
              newData={newData?.data[0] ?? {}}
            />
          </Grid>

        </Grid>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "100vh",
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </Page>
  );
};

export const FrankenDogBowlEventSingleView = () => {
  const { eventId } = useParams();
  return <FrankenDogBowlEventView eventId={eventId} />;
};

const FrankenDogBowlEventSingleViewEB = () => (
  <FrankenDogBowlEventSingleView />
);

export default FrankenDogBowlEventSingleViewEB;
