import React, { useState, useEffect } from "react";
import moment from "moment-timezone";
import { CircularProgress, Checkbox, FormControlLabel, Typography, Box, useTheme } from "@mui/material";
import {
    Brush,
    CartesianGrid,
    Label,
    Line,
    LineChart,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from "recharts";
import axiosInstance from "./../../api/axios/axiosInstance";
import { useTimezone } from "./../../utils/context/Timezone";

const defaultTareValues = [0, 0, 0, 0];

const createLoadCellData = (
    data,
    startTime,
    sampleRate,
    timezone,
    tare = defaultTareValues,
) => {
    return data.map((datum, i) => {
        const time = moment(datum.measure_date + "Z").tz(timezone)
        const timestamp = time.format("HH:mm:ss")
        const d0 = datum.measure_value
        const d1 = datum[1] - tare[1];
        const d2 = datum[2] - tare[2];
        const d3 = datum[3] - tare[3];
        return {
            time: time.valueOf(),
            a: d0,
            b: d1,
            c: d2,
            d: d3,
            total: d0 + d1 + d2 + d3,
            timestamp,
        };
    });
};

const CatFeederEventLoadCellChart = ({ eventId, startTime }) => {
    const theme = useTheme();
    const timezone = useTimezone();
    const [applyTareWeight, setApplyTareWeight] = useState(true);
    const [newData, setNewData] = useState(null);
    const [loadCellData, setLoadCellData] = useState(null);
    const [ld1Data, setLD1Data] = useState("");
    const [pir1Data, setPIR1Data] = useState("");
    const [acc3Data, setACC3Data] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const [loadCellStatus, setLoadCellStatus] = useState("");

    const sDate = startTime ? moment(startTime).format("YYYY-MM-DD HH:mm:ss") : null;
    const url = `/get-cat-feeder-loadcelldata?page=1&event_id=${eventId}`;

    useEffect(() => {
        axiosInstance.get(url)
            .then((response) => {
                setNewData(response.data.data);
                setLoadCellData(response.data.data.loadCellData);
                let ld1filteredData = response.data.data.loadCellData.data.filter(
                    (data) => data.measure_type === "LD1");
                let ld1filteredDatasorted = ld1filteredData?.map(obj => { return { ...obj, measure_date: new Date(obj.measure_date) } })
                    .sort((a, b) => a.measure_date - b.measure_date)
                setLD1Data(ld1filteredDatasorted)
                let pir1filteredData = response.data.data.loadCellData.data.filter(
                    (data) => data.measure_type === "PIR1");

                let pir1filteredDatasorted = pir1filteredData?.map(obj => { return { ...obj, measure_date: new Date(obj.measure_date) } })
                    .sort((a, b) => a.measure_date - b.measure_date)
                setPIR1Data(pir1filteredDatasorted)
                let acc3filteredData = response.data.data.loadCellData.data.filter(
                    (data) => data.measure_type === "ACC3");
                let acc3filteredDatasorted = acc3filteredData?.map(obj => { return { ...obj, measure_date: new Date(obj.measure_date) } })
                    .sort((a, b) => a.measure_date - b.measure_date)
                setACC3Data(acc3filteredDatasorted)
                setLoadCellStatus(response.data.data.data_status)
                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
            });
    }, [eventId]);

    if (isLoading) {
        return <CircularProgress />;
    }

    if (loadCellStatus === "Sensor Data not Available") {
        return (
            <Typography variant="body2" color="textSecondary" style={{ textAlign: "center", verticalAlign: "middle" }}>
                No Sensor Data available.
            </Typography>
        );
    }

    return (
        <div>
        <Box sx={{ height: 400 }}>
            {ld1Data.length > 0 ?
            <ResponsiveContainer>
                <LineChart
                    margin={{ left: 10, right: 10 }}
                    data={createLoadCellData(
                        ld1Data,
                        loadCellData.startTime,
                        loadCellData.sampleRate,
                        timezone,
                        applyTareWeight && loadCellData.tare
                            ? loadCellData.tare
                            : defaultTareValues,
                    )}
                >
                    <XAxis dataKey="timestamp" />
                    <YAxis yAxisId="left">
                        <Label
                            angle={270}
                            position="left"
                            style={{ textAnchor: "middle" }}
                        >
                            Load Cell
                        </Label>
                    </YAxis>
                    <YAxis dataKey="total" yAxisId="right" orientation="right">
                        <Label
                            angle={270}
                            position="right"
                            style={{ textAnchor: "middle" }}
                        >
                            Total
                        </Label>
                    </YAxis>
                    <CartesianGrid stroke={theme.palette.divider} />
                    <Tooltip />
                    <Brush />
                    <Line
                        type="monotone"
                        key="a"
                        dataKey="a"
                        stroke={theme.palette.primary.main}
                        strokeWidth={1}
                        yAxisId="left"
                        dot={false}
                    />
                    
                </LineChart>
            </ResponsiveContainer>
            :
            <Typography variant="body2" color="textSecondary" style={{ textAlign: "center", verticalAlign: "middle" }}>
                No Load Cell Data available.
            </Typography>
        }
        </Box>
        <Box sx={{ height: 400, marginTop: '20px' }}>
        {pir1Data.length > 0 ?
            <ResponsiveContainer>
                <LineChart
                    margin={{ left: 10, right: 10 }}
                    data={createLoadCellData(
                        pir1Data,
                        loadCellData.startTime,
                        loadCellData.sampleRate,
                        timezone,
                        applyTareWeight && loadCellData.tare
                            ? loadCellData.tare
                            : defaultTareValues,
                    )}
                >
                    <XAxis dataKey="timestamp" />
                    <YAxis yAxisId="left">
                        <Label
                            angle={270}
                            position="left"
                            style={{ textAnchor: "middle" }}
                        >
                            Passive Infrared Sensor
                        </Label>
                    </YAxis>
                    <YAxis dataKey="total" yAxisId="right" orientation="right">
                        <Label
                            angle={270}
                            position="right"
                            style={{ textAnchor: "middle" }}
                        >
                            Total
                        </Label>
                    </YAxis>
                    <CartesianGrid stroke={theme.palette.divider} />
                    <Tooltip />
                    <Brush />
                    <Line
                        type="monotone"
                        key="a"
                        dataKey="a"
                        stroke={theme.palette.primary.main}
                        strokeWidth={1}
                        yAxisId="left"
                        dot={false}
                    />
                    
                </LineChart>
            </ResponsiveContainer>
            :
            <Typography variant="body2" color="textSecondary" style={{ textAlign: "center", verticalAlign: "middle" }}>
                No Passive Infrared Sensor Data available.
            </Typography>
        }
        </Box>
        <Box sx={{ height: 400, marginTop: '20px' }}>
        {acc3Data.length > 0 ?
            <ResponsiveContainer>
                <LineChart
                    margin={{ left: 10, right: 10 }}
                    data={createLoadCellData(
                        acc3Data,
                        loadCellData.startTime,
                        loadCellData.sampleRate,
                        timezone,
                        applyTareWeight && loadCellData.tare
                            ? loadCellData.tare
                            : defaultTareValues,
                    )}
                >
                    <XAxis dataKey="timestamp" />
                    <YAxis yAxisId="left">
                        <Label
                            angle={270}
                            position="left"
                            style={{ textAnchor: "middle" }}
                        >
                            Accelerometer
                        </Label>
                    </YAxis>
                    <YAxis dataKey="total" yAxisId="right" orientation="right">
                        <Label
                            angle={270}
                            position="right"
                            style={{ textAnchor: "middle" }}
                        >
                            Total
                        </Label>
                    </YAxis>
                    <CartesianGrid stroke={theme.palette.divider} />
                    <Tooltip />
                    <Brush />
                    <Line
                        type="monotone"
                        key="a"
                        dataKey="a"
                        stroke={theme.palette.primary.main}
                        strokeWidth={1}
                        yAxisId="left"
                        dot={false}
                    />
                    
                </LineChart>
            </ResponsiveContainer>
            :
            <Typography variant="body2" color="textSecondary" style={{ textAlign: "center", verticalAlign: "middle" }}>
                No Accelerometer Sensor Data available.
            </Typography>
        }
        </Box>
        </div>
    );
};

export default CatFeederEventLoadCellChart;
