import React from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  Box,
  Typography,
  Link as MuiLink,
  Tooltip,
  IconButton,
  useTheme,
  Card,
  CardContent,
} from "@mui/material";
import { FileCopy as FileCopyIcon } from "@mui/icons-material";
import { CopyToClipboard } from "react-copy-to-clipboard";

const useStyles = (theme) => ({
  keyInfoWrapper: {
    padding: theme.spacing(2),
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
  },
  space: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(1),
    "& > div:first-of-type": {
      width: "2.5rem",
    },
  },
  infoRow: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
  },
});

const shortenGraphId = (id) => {
  const start = id.slice(0, 6);
  const end = id.slice(-6);
  return `${start}...${end}`;
};

const CopyToClipboardButton = ({ textToCopy, testId }) => (
  <CopyToClipboard text={textToCopy}>
    <Tooltip title="Copy to clipboard">
      <IconButton data-testid={testId}>
        <FileCopyIcon fontSize="small" />
      </IconButton>
    </Tooltip>
  </CopyToClipboard>
);

const FrankenDogBowlEventKeyInfo = ({ eventId, serialNumber }) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const url = `${window.location.origin}/dog-bowl/events/${eventId}`;
  const urlForLink = `/dog-bowl/events/${eventId}`;
  const eventIdWithMiddleStrippedOut = shortenGraphId(eventId);
  const urlShortened = `${window.location.origin}/dog-bowl/events/${eventIdWithMiddleStrippedOut}`;

  return (
    <Box className={classes.keyInfoWrapper}>
      <Card>
        <CardContent>
          {serialNumber && (
            <Box className={classes.infoRow}>
              <Typography variant="body2" component="div">
                SN
              </Typography>
              <Typography variant="body2" component="b">
                {serialNumber}
              </Typography>
            </Box>
          )}
          <Box className={classes.infoRow}>
            <Typography variant="body2" component="div">
              ID
            </Typography>
            <Typography
              variant="body2"
              component="div"
              style={{ display: "flex", alignItems: "center" }}
            >
              <Typography
                variant="body2"
                component="span"
                style={{ marginRight: theme.spacing(1) }}
              >
                {eventId}
              </Typography>
              <CopyToClipboardButton
                textToCopy={eventId}
                testId={"tids.eventView.copyEventIdToClipboard"}
              />
            </Typography>
          </Box>
          <Box className={classes.infoRow}>
            <Typography variant="body2" component="div"  fontWeight="bold">
              Link
            </Typography>
            <Box display="flex" alignItems="center">
              <MuiLink component={RouterLink} to={urlForLink} underline="hover">
                {urlShortened}
              </MuiLink>
              <CopyToClipboardButton
                textToCopy={url}
                testId={"tids.eventView.copyEventLinkToClipboard"}
              />
            </Box>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default FrankenDogBowlEventKeyInfo;
